import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, APP_INITIALIZER, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import * as Sentry from '@sentry/angular-ivy';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from '@app/modules/shared/factories/http-loader.factory';
import localeBE from '@angular/common/locales/nl-BE';
import { registerLocaleData, LocationStrategy } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule } from '@busacca/ng-pick-datetime';
import { Interceptor } from './modules/shared/providers/interceptor';
import { SharedModule } from '@shared/shared.module';
import { initializeSentry } from '@shared-providers/error.handler';
import { AutosizeModule } from 'ngx-autosize';
import { DefaultIntl } from '@shared-libs/date-picker-locale';
import { PathPreserveQueryLocationStrategy } from '@shared-libs/query-params.location-strategy';

initializeSentry();
registerLocaleData(localeBE);

@NgModule({
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		IonicModule.forRoot({
			animated: false,
		}),
		TranslateModule.forRoot({
			defaultLanguage: 'nl-BE',
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		AppRoutingModule,
		HttpClientModule,
		NgSelectModule,
		AutosizeModule,
		LeafletModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		SharedModule,
	],
	providers: [
		{ provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
		{ provide: Sentry.TraceService, deps: [Router] },
		{ provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: LocationStrategy, useClass: PathPreserveQueryLocationStrategy },
		{ provide: LOCALE_ID, useValue: 'nl-BE' },
		{ provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
		{ provide: OwlDateTimeIntl, useClass: DefaultIntl },
		HttpClient,
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	/**
	 * Allows for retrieving singletons using `AppModule.injector.get(MyService)`
	 */
	static injector: Injector;

	constructor(private readonly injector: Injector) {
		AppModule.injector = this.injector;
	}
}
