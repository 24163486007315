import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RoleDirective } from './role.directive';
import { SelectIconDirective } from './select-icon.directive';
import { NotRoleDirective } from './notRole.directive';
import { NavigateDirective } from './navigate.directive';
import { ClientUserApprovedDirective } from './client-user-approved.directive';
import { ClientUserNotApprovedDirective } from './client-user-not-approved.directive';

@NgModule({
	declarations: [
		RoleDirective,
		NotRoleDirective,
		SelectIconDirective,
		NavigateDirective,
		ClientUserApprovedDirective,
		ClientUserNotApprovedDirective,
	],
	imports: [CommonModule],
	exports: [
		RoleDirective,
		NotRoleDirective,
		SelectIconDirective,
		NavigateDirective,
		ClientUserApprovedDirective,
		ClientUserNotApprovedDirective,
	],
})
export class DirectivesModule {}
