<div class="_add-contact-info-component inner-content">
	
	<popup-header-component [showCloseButton]="false">
		<p class="_title" [translate]="'UPDATE_CONTACT_INFO.TITLE'"></p>
        <p class="_subtitle" [translate]="'UPDATE_CONTACT_INFO.SUBTITLE'"></p>
	</popup-header-component>

	<div class="_container">
		
		<form-input *ngIf="missingProperties.includes('contactFirstname')" [formControl]="form.get('contactFirstname')" label="{{'COMPANY.CONTACT.FIRSTNAME' | translate}}"></form-input>
		<form-input *ngIf="missingProperties.includes('contactLastname')" [formControl]="form.get('contactLastname')" label="{{'COMPANY.CONTACT.LASTNAME' | translate}}"></form-input>
		<form-input *ngIf="missingProperties.includes('contactEmail')" [formControl]="form.get('contactEmail')" label="{{'COMPANY.CONTACT.EMAIL' | translate}}"></form-input>
		<form-input *ngIf="missingProperties.includes('contactPhone')" [formControl]="form.get('contactPhone')" label="{{'COMPANY.CONTACT.PHONE' | translate}}"></form-input>

		<div class="_buttons-container">
			<button class="_button _create-button" (click)="updateContactInfo()">
				<span [translate]="'UPDATE_CONTACT_INFO.UPDATE'"></span>
			</button>
		</div>
	</div>

</div>