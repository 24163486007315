import { Api } from '@shared-providers/api.provider';
import { Injectable } from '@angular/core';
import { Provider } from '@shared-providers/Provider';
import { LoadingService } from '@shared-services/loading.service';
import { ICompany, ISearchCompany, ISearchDuplicateCompany } from '@shared-models/company.model';
import { Observable } from 'rxjs';
import { IApiObject } from '@shared-libs/interfaces';
import { UserOnCompanyStatus } from '@shared-libs/enums';

@Injectable({
	providedIn: 'root',
})
export class ClientsCompanyProvider extends Provider<ICompany> {
	protected readonly url: string = '/companies';

	constructor(
		protected readonly api: Api,
		protected readonly loadingService: LoadingService
	) {
		super(loadingService);
	}

	public getMissingContactInfo(): Observable<
		IApiObject<['contactFirstname'?, 'contactLastname'?, 'contactEmail'?, 'contactPhone'?]>
	> {
		return this.process(this.api.get(`${this.url}/missing-contact-info`)) as Observable<IApiObject>;
	}

	public searchDuplicateCompany(company: ISearchDuplicateCompany): Observable<IApiObject<ICompany>> {
		return this.process(this.api.post(`${this.url}/duplicate`, company)) as Observable<IApiObject>;
	}

	public searchCompany(
		company: ISearchCompany
	): Observable<IApiObject<{ status: UserOnCompanyStatus; Company: ISearchCompany }>> {
		return this.process(this.api.post(`${this.url}/search`, company)) as Observable<IApiObject>;
	}

	public createCompany(company: ICompany): Observable<IApiObject<ICompany>> {
		return this.process(this.api.post(`${this.url}`, company)) as Observable<IApiObject>;
	}

	public updateCompanyAsClient(companyId: string, company: ICompany): Observable<IApiObject<ICompany>> {
		return this.process(this.api.put(`${this.url}/${companyId}/client`, company)) as Observable<IApiObject>;
	}

	public updateMissingContactInfo(
		company: Partial<Pick<ICompany, 'contactFirstname' | 'contactLastname' | 'contactEmail' | 'contactPhone'>>
	): Observable<IApiObject> {
		return this.process(this.api.put(`${this.url}/contact-info`, company)) as Observable<IApiObject>;
	}
}
