import { Api } from '@shared-providers/api.provider';
import { Observable } from 'rxjs';
import { IApiObject, IDataQuery, IListData } from '@shared-libs/interfaces';
import { Injectable } from '@angular/core';
import { LoadingService } from '@shared-services/loading.service';
import { ITraining } from '@internal-models/training.model';
import { SharedTrainingProvider } from '@shared-providers/api/training.provider';
import { IBookClosedTrainingForm } from '@shared-factories/forms/book-training-form.factory';
import { ITrainingCancellation } from '@internal-models/training-cancellation.model';
import { IUpdateTrainingInvoiceDetails } from '@internal-factories/forms/training-invoice-details-form.factory';
import { formatQueryUrl } from '@shared-libs/helpers';

@Injectable({
	providedIn: 'root',
})
export class InternalTrainingProvider extends SharedTrainingProvider {
	protected readonly url: string = '/trainings';

	constructor(
		protected readonly api: Api,
		protected readonly loadingService: LoadingService
	) {
		super(api, loadingService);
	}

	public getAllOfTrainee(query: IDataQuery, traineeId: string): Observable<IApiObject<IListData<ITraining>>> {
		return this.process(
			this.api.get(`${this.url}/trainee/${traineeId}?query=${formatQueryUrl(query)}`),
			!query.offset
		) as Observable<IApiObject>;
	}

	public getOne(trainingId: string): Observable<IApiObject<ITraining>> {
		return this.process(this.api.get(`${this.url}/${trainingId}`)) as Observable<IApiObject>;
	}

	public getOneOfCompany(trainingId: string, companyId: string): Observable<IApiObject<ITraining>> {
		return this.process(this.api.get(`${this.url}/${trainingId}/${companyId}`)) as Observable<IApiObject>;
	}

	public getAllNamesForFilter(): Observable<IApiObject<Array<Pick<ITraining, 'nameNL' | 'nameEN'>>>> {
		return this.process(this.api.get(`${this.url}/names/all`)) as Observable<IApiObject>;
	}

	public getUnseen(): Observable<IApiObject<number>> {
		return this.process(this.api.get(`${this.url}/unseen`), false) as Observable<IApiObject>;
	}

	public create(training: ITraining): Observable<IApiObject<ITraining>> {
		return this.process(this.api.post(`${this.url}`, training)) as Observable<IApiObject>;
	}

	public updateOpen(trainingId: string, training: ITraining): Observable<IApiObject<ITraining>> {
		return this.process(this.api.put(`${this.url}/${trainingId}`, training)) as Observable<IApiObject>;
	}

	public updateClosed(trainingId: string, training: IBookClosedTrainingForm): Observable<IApiObject<ITraining>> {
		return this.process(this.api.put(`${this.url}/closed/${trainingId}`, training)) as Observable<IApiObject>;
	}

	public updateInvoiceDetails(
		trainingId: string,
		invoiceDetails: IUpdateTrainingInvoiceDetails
	): Observable<IApiObject<ITraining>> {
		return this.process(
			this.api.put(`${this.url}/invoice-details/${trainingId}`, invoiceDetails)
		) as Observable<IApiObject>;
	}

	public updateAmountOfAttendees(trainingId: string, amountOfAttendees: number): Observable<IApiObject<ITraining>> {
		return this.process(
			this.api.put(`${this.url}/amount-of-attendees/${trainingId}`, { amountOfAttendees })
		) as Observable<IApiObject>;
	}

	public markAsSeen(trainingId: string): Observable<IApiObject<ITraining>> {
		return this.process(this.api.put(`${this.url}/${trainingId}/unseen`, {})) as Observable<IApiObject>;
	}

	public complete(trainingId: string): Observable<IApiObject<ITraining>> {
		return this.process(this.api.put(`${this.url}/${trainingId}/complete`, {})) as Observable<IApiObject>;
	}

	public completeForTraineeOnTraining(
		trainingId: string,
		traineeOnTrainingId: string
	): Observable<IApiObject<ITraining>> {
		return this.process(
			this.api.put(`${this.url}/${trainingId}/complete/${traineeOnTrainingId}`, {})
		) as Observable<IApiObject>;
	}

	public delete(trainingId: string, cancellation?: ITrainingCancellation): Observable<IApiObject> {
		return this.process(this.api.delete(`${this.url}/${trainingId}`, cancellation));
	}
}
