import { Injectable } from '@angular/core';
import { StorageService } from '@shared-services/storage.service';
import { StorageKey, Role } from '@shared-libs/enums';
import { IUser } from '@shared/models/user.model';
import { forkJoin, Observable } from 'rxjs';

/* TODO: replace managers with redux strategy? */

/**
 * The manager for the user object. A Manager is responsible for the state and reusability (across the application) of certain properties
 * @see {@link IUser}
 */
@Injectable({
	providedIn: 'root',
})
export class UserManager {
	private user: IUser;
	private token: string;
	private refreshToken: string;

	constructor(private readonly storageService: StorageService) {}

	/**
	 * Set the user object in session storage
	 * @param _user The user that is set
	 */
	public setUser(_user: IUser): void {
		this.user = _user;
	}

	/**
	 * Save the user currently in session storage to persistent storage
	 * @param _user The user
	 */
	public saveUser(_user?: IUser): void {
		if (_user) {
			this.user = _user;
		}
		if (this.user) {
			this.storageService.setItem(StorageKey.user, this.user).subscribe();
		}
	}

	/**
	 * Get the user, firstly from session storage and secondly from persistent storage
	 * @returns An observable that returns a {@link IUser}
	 */
	public getUser(): Observable<IUser> {
		return new Observable((subscriber) => {
			if (this.user) {
				subscriber.next(this.user);
			} else {
				return this.storageService.getItem<IUser>(StorageKey.user).subscribe(
					(user) => {
						if (user) {
							this.setUser(user);
							subscriber.next(user);
						} else subscriber.error();
					},
					(error) => subscriber.error(error)
				);
			}
		});
	}

	/**
	 * Remove the user from session and persistent storage
	 */
	public removeUser(): void {
		this.user = null;
		this.token = null;
		this.refreshToken = null;
		this.storageService.removeItem(StorageKey.user).subscribe();
		this.storageService.removeItem(StorageKey.token).subscribe();
		this.storageService.removeItem(StorageKey.refreshToken).subscribe();
	}

	/**
	 * Get the api token of the current user.
	 * It is used as authorization method for api requests.
	 * @returns The api token
	 */
	public getToken(): string {
		return this.token;
	}

	/**
	 * Set the token of the current user in session storage
	 * @param _token A new token for the user
	 */
	public setToken(_token: string): void {
		this.token = _token;
	}

	/**
	 * Set the token of the current user in persistent storage
	 * @param _token A new token for the user
	 */
	public saveToken(_token?: string): void {
		if (_token) {
			this.token = _token;
		}
		if (this.token) {
			this.storageService.setItem(StorageKey.token, this.token).subscribe(() => {});
		}
	}

	/**
	 * Get the refresh token of the current user.
	 * It is used to get a new token when it is expired
	 * @returns The refresh token
	 */
	public getRefreshToken(): string {
		return this.refreshToken;
	}

	/**
	 * Set the refresh token of the current user in session storage
	 * It is used to get a new token when it is expired
	 * @param _token The refresh token
	 */
	public setRefreshToken(_token: string): void {
		this.refreshToken = _token;
	}

	/**
	 * Set the refresh token of the current user in persistent storage
	 * @param _token The refresh token
	 */
	public saveRefreshToken(_token?: string): void {
		if (_token) {
			this.refreshToken = _token;
		}
		if (this.refreshToken) {
			this.storageService.setItem(StorageKey.refreshToken, this.refreshToken).subscribe(() => {});
		}
	}

	/**
	 * Get the user id of the current user
	 * @returns The user id
	 */
	public getUserId(): string {
		return this.user?.sub;
	}

	/**
	 * Get the user email of the current user
	 * @returns The user email
	 */
	public getUserEmail(): string {
		return this.user?.email;
	}

	public getUserDetailsId(): string {
		switch (this.user?.role) {
			case Role.PLANNER:
				return this.user?.PlannerId;
			case Role.TRAINER:
				return this.user?.TrainerId;
		}
	}

	/**
	 * Get the role of the current user
	 * @returns The role as {@link Role}
	 */
	public getRole(): Role {
		return this.user?.role;
	}

	/**
	 * Get whether the current user is a client
	 * @returns Whether the user is a client or not
	 */
	public isClient(): boolean {
		return this.user?.role === Role.CLIENT;
	}

	/**
	 * Get whether the current user is a trainer
	 * @returns Whether the user is a trainer or not
	 */
	public isTrainer(): boolean {
		return this.user?.role === Role.TRAINER;
	}

	/**
	 * Get whether the current user is a planner
	 * @returns Whether the user is a planner or not
	 */
	public isPlanner(): boolean {
		return this.user?.role === Role.PLANNER;
	}

	/**
	 * Get whether the current user is an admin
	 * @returns Whether the user is an admin or not
	 */
	public isAdmin(): boolean {
		return this.user?.role === Role.ADMIN;
	}

	/**
	 * Get whether the current user is a planner or admin
	 * @returns Whether the user is a planner/admin or not
	 */
	public isPlannerOrAdmin(): boolean {
		return this.user?.role === Role.PLANNER || this.user?.role === Role.ADMIN;
	}

	/**
	 * A check whether the user is authenticated
	 * When true, it returns the user
	 * @returns An observable with the user
	 */
	public isAuthenticated(): Observable<IUser> {
		return new Observable((subscriber) => {
			if (this.user) {
				subscriber.next(this.user);
			} else {
				return forkJoin({
					user: this.storageService.getItem<IUser>(StorageKey.user),
					token: this.storageService.getItem<string>(StorageKey.token),
					refreshToken: this.storageService.getItem<string>(StorageKey.refreshToken),
				}).subscribe(
					({ user, token, refreshToken }) => {
						if (user && token && refreshToken) {
							this.setUser(user);
							this.setToken(token);
							this.setRefreshToken(refreshToken);
							subscriber.next(user);
						} else subscriber.error();
					},
					(error) => subscriber.error(error)
				);
			}
		});
	}
}
