import { OwlDateTimeIntl } from '@busacca/ng-pick-datetime';
import { Injectable } from '@angular/core';

@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
	/** A label for the up second button (used by screen readers).  */
	readonly upSecondLabel: string = 'Seconde toevoegen';

	/** A label for the down second button (used by screen readers).  */
	readonly downSecondLabel: string = 'Seconde aftrekken';

	/** A label for the up minute button (used by screen readers).  */
	readonly upMinuteLabel: string = 'Minuut toevoegen';

	/** A label for the down minute button (used by screen readers).  */
	readonly downMinuteLabel: string = 'Minuut aftrekken';

	/** A label for the up hour button (used by screen readers).  */
	readonly upHourLabel: string = 'Uur toevoegen';

	/** A label for the down hour button (used by screen readers).  */
	readonly downHourLabel: string = 'Uur aftrekken';

	/** A label for the previous month button (used by screen readers). */
	readonly prevMonthLabel: string = 'Vorige maand';

	/** A label for the next month button (used by screen readers). */
	readonly nextMonthLabel: string = 'Volgende maand';

	/** A label for the previous year button (used by screen readers). */
	readonly prevYearLabel: string = 'Vorig jaar';

	/** A label for the next year button (used by screen readers). */
	readonly nextYearLabel: string = 'Volgend jaar';

	/** A label for the previous multi-year button (used by screen readers). */
	readonly prevMultiYearLabel: string = 'Vorige 21 jaar';

	/** A label for the next multi-year button (used by screen readers). */
	readonly nextMultiYearLabel: string = 'Volgende 21 jaar';

	/** A label for the 'switch to month view' button (used by screen readers). */
	readonly switchToMonthViewLabel: string = 'Verander naar maand weergave';

	/** A label for the 'switch to year view' button (used by screen readers). */
	readonly switchToMultiYearViewLabel: string = 'Kies maand en jaar';

	/** A label for the cancel button */
	readonly cancelBtnLabel: string = 'Annuleren';

	/** A label for the set button */
	readonly setBtnLabel: string = 'Kies';

	/** A label for the range 'from' in picker info */
	readonly rangeFromLabel: string = 'Van';

	/** A label for the range 'to' in picker info */
	readonly rangeToLabel: string = 'Tot';

	/** A label for the hour12 button (AM) */
	readonly hour12AMLabel: string = 'AM';

	/** A label for the hour12 button (PM) */
	readonly hour12PMLabel: string = 'PM';
}
