import { Api } from '@shared-providers/api.provider';
import { Observable } from 'rxjs';
import { IApiObject, IDataQuery, IListData } from '@shared-libs/interfaces';
import { Injectable } from '@angular/core';
import { LoadingService } from '@shared-services/loading.service';
import { CompanyUserProvider } from '@shared-providers/api/company-user.provider';
import { ICompanyUser } from '@internal-models/company-user.model';
import { ILinkCompany } from '@shared-models/company.model';
import { formatQueryUrl } from '@shared-libs/helpers';

@Injectable({
	providedIn: 'root',
})
export class InternalCompanyUserProvider extends CompanyUserProvider {
	protected readonly url: string = '/company-users';

	constructor(
		protected readonly api: Api,
		protected readonly loadingService: LoadingService
	) {
		super(api, loadingService);
	}

	public getAll(query: IDataQuery): Observable<IApiObject<IListData<ICompanyUser>>> {
		return this.process(
			this.api.get(`${this.url}?query=${formatQueryUrl(query)}`),
			!query.offset
		) as Observable<IApiObject>;
	}

	public updateUserLinkToCompany(
		data: Pick<ILinkCompany, 'id' | 'UserId' | 'CompanyId' | 'accountNumber'>
	): Observable<IApiObject<void>> {
		return this.process(this.api.put(`${this.url}`, data)) as Observable<IApiObject>;
	}

	public getPending(): Observable<IApiObject<number>> {
		return this.process(this.api.get(`${this.url}/pending`), false) as Observable<IApiObject>;
	}

	public getAllOfCompany(companyId: string): Observable<IApiObject<ICompanyUser[]>> {
		return this.process(this.api.get(`${this.url}/company/${companyId}`), false) as Observable<IApiObject>;
	}
}
