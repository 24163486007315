import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IFormGroup } from '@shared-libs/interfaces';
import { SharedFormValidators } from '@shared-libs/form-validators';
import { ICompany } from '@shared-models/company.model';

export class ContactInfoFormFactory {
	public static create(
		missingAttributes: ['contactFirstname'?, 'contactLastname'?, 'contactEmail'?, 'contactPhone'?]
	): IFormGroup<Partial<Pick<ICompany, 'contactFirstname' | 'contactLastname' | 'contactEmail' | 'contactPhone'>>> {
		const form = new UntypedFormGroup({});
		for (const attribute of missingAttributes) {
			form.addControl(
				attribute,
				new UntypedFormControl(null, [
					SharedFormValidators.required,
					attribute === 'contactEmail' ? SharedFormValidators.email : () => null,
					attribute === 'contactPhone' ? SharedFormValidators.phone : () => null,
				])
			);
		}
		return form;
	}
}
