export const VALIDATION = {
	EMAIL_REGEX: new RegExp('^[a-zA-Z0-9&._%+-]+@[a-zA-Z0-9-]+.([a-zA-Z0-9-]+.)*[a-zA-Z0-9-]{2,}$'),
	STRING_REGEX: new RegExp('[^]+'),
	NAME_REGEX: new RegExp(
		"^([A-zÀ-úäöüÄÖÜâêûîôÂÊÛÎÔ])([A-zÀ-úäöüÄÖÜâêûîôÂÊÛÎÔ' .-]+)?([A-zÀ-úäöüÄÖÜâêûîôÂÊÛÎÔ .-]+)$"
	),
	PHONE_REGEX: new RegExp('^([+0-9]{9,13})$'),
	ZIPCODE_REGEX: new RegExp('^[1-9]{1}[0-9]{3}$'),
	BIRTHYEAR_REGEX: new RegExp('^(19|20)[0-9]{2}$'),
	TIME_REGEX: /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/,
	// eslint-disable-next-line max-len
	VAT_REGEX: new RegExp(
		'^((AT)?U[0-9]{8}|(BE)?[0-1][0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$'
	),
	DATE_STRING: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
	INPUT_DATE_STRING:
		/(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/,
	INPUT_DATE_STRING_SPLIT: /((0[1-9]|[12]\d|3[01])(-|\/)(0[1-9]|1[0-2])(-|\/)([12]\d{3}))/,

	COUNTRY_CODE: new RegExp('^[A-Z]{2}$'),
	VAT: {
		AT: /^(AT)(U\d{8}$)/i, // Austria
		BE: /^(BE)[0-1](\d{9}$)/i, // Belgium
		BG: /^(BG)(\d{9,10}$)/i, // Bulgaria
		CY: /^(CY)([0-5|9]\d{7}[A-Z]$)/i, // Cyprus
		CZ: /^(CZ)(\d{8,10})?$/i, // Czech Republic
		DE: /^(DE)([1-9]\d{8}$)/i, // Germany
		DK: /^(DK)(\d{8}$)/i, // Denmark
		EE: /^(EE)(10\d{7}$)/i, // Estonia
		EL: /^(EL)(\d{9}$)/i, // Greece
		ES: /^(ES)([0-9A-Z][0-9]{7}[0-9A-Z]$)/i, // Spain
		EU: /^(EU)(\d{9}$)/i, // EU-type
		FI: /^(FI)(\d{8}$)/i, // Finland
		FR: /^(FR)([0-9A-Z]{2}[0-9]{9}$)/i, // France
		GB: /^(GB)((?:[0-9]{12}|[0-9]{9}|(?:GD|HA)[0-9]{3})$)/i, // UK (Standard = 9 digits), (Branches = 12 digits), (Government = GD + 3 digits), (Health authority = HA + 3 digits)
		GR: /^(GR)(\d{8,9}$)/i, // Greece
		HR: /^(HR)(\d{11}$)/i, // Croatia
		HU: /^(HU)(\d{8}$)/i, // Hungary
		IE: /^(IE)([0-9A-Z\*\+]{7}[A-Z]{1,2}$)/i, // Ireland
		IT: /^(IT)(\d{11}$)/i, // Italy
		LV: /^(LV)(\d{11}$)/i, // Latvia
		LT: /^(LT)(\d{9}$|\d{12}$)/i, // Lithunia
		LU: /^(LU)(\d{8}$)/i, // Luxembourg
		MT: /^(MT)([1-9]\d{7}$)/i, // Malta
		NL: /^(NL)(\d{9}B\d{2}$)/i, // Netherlands
		NO: /^(NO)(\d{9}$)/i, // Norway (not EU)
		PL: /^(PL)(\d{10}$)/i, // Poland
		PT: /^(PT)(\d{9}$)/i, // Portugal
		RO: /^(RO)([1-9]\d{1,9}$)/i, // Romania
		RU: /^(RU)(\d{10}$|\d{12}$)/i, // Russia
		RS: /^(RS)(\d{9}$)/i, // Serbia
		SI: /^(SI)([1-9]\d{7}$)/i, // Slovenia
		SK: /^(SK)([1-9]\d[(2-4)|(6-9)]\d{7}$)/i, // Slovakia Republic
		SE: /^(SE)(\d{10}01$)/i, // Sweden
	},
};

export const GENDERS = ['Man', 'Vrouw'];

export const CALENDAR_EVENT_COLORS = {
	WORKBLOCK: '#7fbf7f',
	SUGGESTION: [
		'#ffffff',
		'#f2f8f2',
		'#e5f2e5',
		'#d8ebd8',
		'#cbe5cb',
		'#bfdfbf',
		'#b2d8b2',
		'#a5d2a5',
		'#98cb98',
		'#8bc58b',
		'#7fbf7f',
	],
	PASSED: 'lightgrey',
	VACATION: '#fcc567',
	TRAINING: '#ff7f7f',
	RESERVED_TRAINING: '#ffe5e5',
	RESERVED: '#85C7F2',
	OFFICE: '#7eabab',
	RECOVERY: '#b7aab6',
	SICK: '#C46D5E',
	CLOSING_DAY: '#ff7f7f',
	TRAVEL: 'lightblue',
};

export const TEMPLATE_WIDTH_CHANGE = 1024;

export const TERMS_URL =
	'https://www.rodekruis.be/wat-kan-jij-doen/volg-een-opleiding/onze-opleidingen/algemene-voorwaarden-ehbo-opleiding-bedrijven/';
