import { Observable } from 'rxjs';
import { IApiObject, IListData } from '@shared-libs/interfaces';
import { LoadingService } from '@shared-services/loading.service';

export abstract class Provider<Model> {
	protected readonly url: string = '/';

	constructor(protected readonly loadingService: LoadingService) {}

	/**
	 * Process the request
	 * A framework function to add a loading service and internet listener
	 * @param _request The Api request
	 * @param _showLoading A parameter to show a loading indicator or not
	 * @returns An observable containing the result of the request as {@link IApiObject}
	 */
	protected process<M = Model>(
		_request: Observable<IApiObject<M | Array<M> | IListData<M>>>,
		_showLoading: boolean = true
	): Observable<IApiObject<M | Array<M> | IListData<M>>> {
		if (true /* check for internet */) {
			return new Observable((subscriber) => {
				void this.loadingService
					.startLoading(_showLoading)
					.then(() => {
						_request.subscribe(
							(result) => {
								this.loadingService.stopLoading(_showLoading);
								subscriber.next(result);
							},
							(error) => {
								this.loadingService.stopLoading(_showLoading, error?.status === 0);
								subscriber.error(error);
							},
							() => subscriber.complete()
						);
					})
					.catch();
			});
		} else return new Observable((sub) => sub.complete());
	}
}
