import { Component, Input, OnInit } from '@angular/core';
import { ContactInfoFormFactory } from '@client-factories/forms/contact-info-form.factory';
import { ClientsCompanyProvider } from '@client-providers/company.provider';
import { ModalController } from '@ionic/angular';
import { markFormGroupTouched } from '@shared-libs/helpers';
import { IFormGroup } from '@shared-libs/interfaces';
import { ICompany } from '@shared-models/company.model';

@Component({
	selector: 'add-contact-info-component',
	templateUrl: 'add-contact-info.component.html',
	styleUrls: ['./add-contact-info.component.scss'],
})
export class AddContactInfoComponent implements OnInit {
	@Input() missingProperties: ['contactFirstname'?, 'contactLastname'?, 'contactEmail'?, 'contactPhone'?];

	public form: IFormGroup<
		Partial<Pick<ICompany, 'contactFirstname' | 'contactLastname' | 'contactEmail' | 'contactPhone'>>
	>;

	constructor(
		private readonly companyProvider: ClientsCompanyProvider,
		private readonly modalController: ModalController
	) {}

	public ngOnInit(): void {
		this.form = ContactInfoFormFactory.create(this.missingProperties);
	}

	public updateContactInfo(): void {
		markFormGroupTouched(this.form);

		if (this.form.valid) {
			this.companyProvider.updateMissingContactInfo(this.form.value).subscribe(() => {
				void this.modalController.dismiss();
			});
		}
	}
}
