import { Api } from '@shared-providers/api.provider';
import { Observable } from 'rxjs';
import { IApiObject } from '@shared-libs/interfaces';
import { Injectable } from '@angular/core';
import { Provider } from '@shared-providers/Provider';
import { LoadingService } from '@shared-services/loading.service';
import { ICompany, ILinkCompany } from '@shared-models/company.model';

@Injectable({
	providedIn: 'root',
})
export class CompanyUserProvider extends Provider<void> {
	protected readonly url: string = '/company-users';

	constructor(
		protected readonly api: Api,
		protected readonly loadingService: LoadingService
	) {
		super(loadingService);
	}

	public linkUserToCompany(data: ILinkCompany): Observable<IApiObject<void>> {
		return this.process(this.api.post(`${this.url}`, data)) as Observable<IApiObject>;
	}

	public getCompaniesOfUser(userId: string): Observable<IApiObject<ICompany[]>> {
		return this.process(this.api.get(`${this.url}/${userId}/companies`)) as Observable<IApiObject>;
	}

	public removeUserLinkToCompany(data: Pick<ILinkCompany, 'UserId' | 'CompanyId'>): Observable<IApiObject<void>> {
		return this.process(this.api.delete(`${this.url}`, data)) as Observable<IApiObject>;
	}
}
