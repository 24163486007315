import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationService } from '@shared-services/navigation.service';

@Component({
	selector: 'popup-header-component',
	templateUrl: 'popup-header.component.html',
	styleUrls: ['./popup-header.component.scss'],
})
export class PopupHeaderComponent {
	@Input() public showCloseButton: boolean = true;
	@Output() public closed: EventEmitter<void> = new EventEmitter();

	constructor(private readonly navigationService: NavigationService) {}

	public close(): void {
		this.closed.emit();
		this.navigationService.navigateBack();
	}
}
