<div class="_confirmation-change-component">

	<popup-header-component [showCloseButton]="false">
		<p class="_title" [translate]="'CONFIRMATION.TITLE'"></p>
	</popup-header-component>

    <p class="_confirmation-question">
		<span [translate]="'CONFIRMATION.BASE_MESSAGE'"></span>
		<span translate="{{'CONFIRMATION.MESSAGES.' + messageKey}}"></span>
	</p>

    <div class="_buttons-container">
        <button type="button" (click)="answer(false)" class="_button _no-button" [translate]="'BUTTONS.NO'"></button>
        <button type="button" (click)="answer(true)" class="_button _yes-button" [translate]="'BUTTONS.YES'"></button>
    </div>
</div>
