import { Api } from '@shared-providers/api.provider';
import { Observable } from 'rxjs';
import { IApiObject, IChangePasswordCredentials, ILoginCredentials } from '@shared-libs/interfaces';
import { Injectable } from '@angular/core';
import { Provider } from '@shared-providers/Provider';
import { LoadingService } from '@shared-services/loading.service';
import { IUser } from '@shared/models/user.model';

@Injectable({
	providedIn: 'root',
})
export class SharedUserProvider extends Provider<IUser> {
	protected readonly url: string = '/users';

	constructor(
		protected readonly api: Api,
		protected readonly loadingService: LoadingService
	) {
		super(loadingService);
	}

	public login(
		_credentials: ILoginCredentials
	): Observable<IApiObject<{ access_token: string; refresh_token: string }>> {
		return this.process(this.api.post<any>(`${this.url}/login`, _credentials)) as Observable<IApiObject>;
	}

	public updatePassword(_credentials: IChangePasswordCredentials): Observable<IApiObject> {
		return this.process(this.api.post(`${this.url}/update-password`, _credentials));
	}

	public isActive(): Observable<IApiObject<boolean>> {
		return this.process(this.api.get(`${this.url}/active`)) as Observable<IApiObject>;
	}
}
