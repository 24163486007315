import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'validation-error',
	templateUrl: 'validation-error.component.html',
	styleUrls: ['./validation-error.component.scss'],
})
export class ValidationErrorComponent {
	@Input() public control: UntypedFormControl | UntypedFormGroup;
}
