import { Component } from '@angular/core';
import { NavigationDataKey } from '@shared-libs/enums';
import { NavigationService } from '@shared-services/navigation.service';

@Component({
	selector: 'confirmation-component',
	templateUrl: 'confirmation.component.html',
	styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
	public readonly messageKey: string;

	constructor(private readonly navigationService: NavigationService) {
		this.messageKey = this.navigationService.getNavigationData(NavigationDataKey.message);
	}

	public answer(_answer: boolean): void {
		this.navigationService.navigateBack(_answer, false);
	}
}
