import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

/**
 * The loading service that handles showing and dismissing of a loading indicator.
 * A maximum of 1 loading indicator should be show at a given time.
 */
@Injectable({
	providedIn: 'root',
})
export class LoadingService {
	private loaderUI: HTMLIonLoadingElement;
	private loadingRequests: number = 0;
	private startingLoading: boolean = false;
	private stopAllIsFired: boolean = false;

	constructor(
		private readonly loadingController: LoadingController,
		private readonly translateService: TranslateService
	) {}

	/**
	 * Start a loading indicator
	 * @param _showLoading Whether a loading indicator should be shown
	 * @param _message A message to show in the loading indicator (default: 'Even geduld')
	 * @returns An empty promise confirming that the loading indicator is shown
	 */
	public async startLoading(_showLoading: boolean, _message?: string): Promise<void> {
		if (_showLoading) {
			this.loadingRequests++;
			if (!this.loaderUI && !this.startingLoading) {
				this.startingLoading = true;
				this.loaderUI = await this.loadingController.create({
					message: _message || this.translateService.instant('LOADING_MESSAGE'),
					cssClass: '_loading-indicator',
					spinner: 'bubbles',
				});
				if (!this.stopAllIsFired) {
					await this.loaderUI.present();
				} else {
					this.stopAllIsFired = false;
					this.loaderUI = null;
				}
				this.startingLoading = false;
			}
		}
	}

	/**
	 * Stop a loading indicator
	 * @param _showLoading Whether the loading indicator that should be stopped, is shown
	 * @param stopAll [default = false] Whether or not to stop all loading indicators
	 */
	public stopLoading(_showLoading?: boolean, stopAll: boolean = false): void {
		if (stopAll) {
			this.stopAllIsFired = true;
		}
		if (_showLoading || stopAll) {
			if (this.loaderUI) {
				if (this.loadingRequests === 1 || stopAll) {
					void this.loaderUI.dismiss().catch();
					this.loaderUI = null;
					this.stopAllIsFired = false;
				}
			}
			if (this.loadingRequests > 0) {
				this.loadingRequests--;
			}
			if (stopAll) {
				this.loadingRequests = 0;
			}
		}
	}
}
