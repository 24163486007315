import {
	StringOrOrderDirection,
	SelectFilterDataRequest,
	Filter,
	ITableFilterOptions,
	ITableDateFilterOptions,
	ITableSelectFilterOptions,
	ITableInputFilterOptions,
	TableSelectFilterType,
	IApiObject,
} from './interfaces';
import { OrderDirection, Role, TableInputFilterSearchType } from './enums';
import { Observable, Subject } from 'rxjs';

/**
 * A class that represents a table header which includes the possibility to sort the data in the table
 */
export class TableHeader {
	name: string;
	reference: [string, StringOrOrderDirection?, OrderDirection?];
	size: number;
	orderDirection: OrderDirection;
	order?: number;

	/**
	 * Create a new table header instance
	 * @param _name The name that appears as the table header
	 * @param _reference The name of the property to sort on (default is _name), optionally with a nested object or a default order direction
	 * @param _size The number of columns the header takes in (default is 1)
	 */
	constructor(
		_name?: string,
		_reference: [string, StringOrOrderDirection?, OrderDirection?] = [_name],
		_size: number = 1
	) {
		this.name = _name;
		this.reference = _reference;
		this.size = _size;
		if (_reference && Object.values(OrderDirection).includes(this.reference[this.reference.length - 1] as any)) {
			this.orderDirection = this.reference[this.reference.length - 1] as any;
		}
	}
}

/**
 * @abstract
 */
export abstract class TableFilter<FilterType = Filter> {
	filter: FilterType;
	property: string;
	default: any;
	showing: boolean;
	translationKey: string;
	roles: Array<Role>;
	current: any;

	constructor(_options: ITableFilterOptions<FilterType>) {
		this.property = _options.property;
		this.filter = _options.filter;
		this.showing = _options.showing;
		this.translationKey = _options.translationKey;
		this.default = _options.default;
		this.roles = _options.roles;
	}

	/**
	 * Get the default query of a table filter
	 * @param current the current query
	 * @returns the query
	 */
	public getDefaultQuery(current: any): { [key: string]: any } {
		return {
			...current,
			...this.default,
		};
	}

	public setState(_filter: TableFilter): void {
		this.current = _filter.current;
		this.showing = _filter.showing;
	}
}

/**
 * A class that represents a table filter that allows us to filter the data in the table based on the date range
 * @augments TableFilter
 */
export class TableDateFilter extends TableFilter<{ [key: string]: any }> {
	/**
	 * Create a new instance of a table date filter
	 * @param _options {@link ITableDateFilterOptions<{}>}
	 */
	constructor(_options: ITableDateFilterOptions<{ [key: string]: any }>) {
		super(_options);
		this.current = _options.current;
	}
}

/* TODO: expand with correct typing for default */

/**
 * A class that represents a table filter that allows us to filter the data in the table based on one or more options in a selection
 * @augments TableFilter
 */
export class TableSelectFilter<Model = { name: string; value: string | number | boolean }> extends TableFilter<
	SelectFilterDataRequest<Model>
> {
	type: TableSelectFilterType;
	resource: 'online' | 'local';

	/**
	 *
	 * @param _options {@link ITableDateFilterOptions<{}>}
	 */
	constructor(_options: ITableSelectFilterOptions<SelectFilterDataRequest<Model>>) {
		super(_options);
		this.type = _options.type || '$or';
		this.resource = _options.resource || 'local';
	}

	/**
	 * Get the default query of a table filter
	 * @param current the current query
	 * @returns the query
	 */
	public getDefaultQuery(current: any = []): { [key: string]: any } {
		return [...current, ...this.default];
	}
}

/**
 * A class that represents a table filter that allows us to filter the data in the table based on an input value
 * @augments TableFilter
 */
export class TableInputFilter extends TableFilter<{ [key: string]: any }> {
	validation: RegExp;
	showing: boolean;
	searchType: TableInputFilterSearchType;

	/**
	 *
	 * @param _options {@link ITableDateFilterOptions<{}>}
	 */
	constructor(_options: ITableInputFilterOptions<{ [key: string]: any }>) {
		super(_options);
		this.validation = _options.validation;
	}

	/**
	 * Get the default query of a table filter
	 * @returns the default query
	 */
	public getDefaultQuery(): { [key: string]: any } {
		this.current = this.default;
		return this.default;
	}
}

/**
 * An abstract class that indicates the functionality for the context system.
 */
export abstract class ContextHandler<Component = any> {
	protected readonly component: Component;

	constructor(component: Component) {
		this.component = component;
	}

	public getContext(): any {
		return this.component;
	}
}

/**
 * A class that encloses the functionality for the badge system.
 * The use of observer and observable for auto-updating & manually updating the badge.
 * The default refresh rate is 10 min.
 */
export class Badge {
	private request: Observable<IApiObject<number>>;
	private subject: Subject<number>;

	constructor(_request: Observable<IApiObject<number>>, _refreshRate: number = 60000 * 10) {
		this.request = _request;
		this.subject = new Subject();
		this.updateBadge();
		setInterval(() => this.updateBadge(), _refreshRate);
	}

	/**
	 * Update the the badge using the request that is passed through
	 * during the initialize (construct) of a Badge
	 *
	 * Emits the actual count using the observer on which the subscribe is set
	 */
	public updateBadge(): void {
		this.request?.subscribe(
			(result) => {
				this.subject.next(result.data);
			},
			() => {}
		);
	}

	/**
	 * A get method to get the observable that returns the accurate count.
	 * Update badge is called again to make sure it is properly initialized
	 * @returns The observable
	 */
	public getObservable(): Subject<number> {
		this.updateBadge();
		return this.subject;
	}

	/**
	 * Destroy the observable & observer
	 */
	public destroy(): void {
		this.request = null;
		this.subject.complete();
		setTimeout(() => {
			this.subject = null;
		}, 100);
	}
}
