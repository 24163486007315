import { Injectable } from '@angular/core';
import { Badge } from '@shared-libs/classes';
import { UserManager } from '@app/modules/shared/managers/user.manager';
import { InternalTrainingProvider } from '@internal-providers/api/training.provider';
import { Observable } from 'rxjs';
import { InternalCompanyUserProvider } from '@internal-providers/api/company-user.provider';

/**
 * The service that handles the badges
 * Should be ran on webworker instead of main thread.
 */
@Injectable({
	providedIn: 'root',
})
export class BadgeService {
	public trainingsBadge: Badge;
	public companyUsersBadge: Badge;

	constructor(
		private readonly trainingProvider: InternalTrainingProvider,
		private readonly companyUserProvider: InternalCompanyUserProvider,
		private readonly userManager: UserManager
	) {}

	public getTrainingsBadge(): Observable<number> {
		if (this.userManager.isPlannerOrAdmin()) {
			if (!this.trainingsBadge) {
				this.trainingsBadge = new Badge(this.trainingProvider.getUnseen());
			}
			return this.trainingsBadge.getObservable();
		}
	}

	public getCompanyUsersBadge(): Observable<number> {
		if (this.userManager.isPlannerOrAdmin()) {
			if (!this.companyUsersBadge) {
				this.companyUsersBadge = new Badge(this.companyUserProvider.getPending());
			}
			return this.companyUsersBadge.getObservable();
		}
	}

	/**
	 * Destroy the BadgeService and individual badges
	 */
	public destroy(): void {
		this.trainingsBadge?.destroy();
		this.trainingsBadge?.destroy();
		this.trainingsBadge = null;
		this.companyUsersBadge = null;
	}
}
