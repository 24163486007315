import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { NavigationDataKey, NavigationType, Role } from '@shared-libs/enums';
import { INavigationObject, INavigationOptions } from '@shared-libs/interfaces';
import { NavigationDataManager } from '@app/modules/shared/managers/navigation-data.manager';
import { findLast } from 'lodash';
import { UserManager } from '@shared-managers/user.manager';
import { filter, pairwise } from 'rxjs';

const baseNavigationOptions: INavigationOptions = {
	addToHistory: true,
	closePreviousModals: true,
};

/**
 * The navigation service that handles navigation (forward and back) in the app
 * We use a custom service to save a history to make sure we can navigate back
 * to the correct page, modal or popover.
 */
@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	private history: Array<INavigationObject> = new Array();
	private lastPoppedHistory: INavigationObject;

	constructor(
		private readonly modalController: ModalController,
		private readonly popoverController: PopoverController,
		private readonly router: Router,
		private readonly navigationController: NavController,
		private readonly navigationDataManager: NavigationDataManager,
		private readonly userManager: UserManager
	) {}

	/**
	 * A method used to navigate to a component or page, that enables us to handle navigation data and and returning between closed modals
	 * @param {NavigationType} _type The type of the navigation to use
	 * @param _destination The destination to navigate to (either an url or a component)
	 * @param _data Data to pass to the component
	 * @param _options The navigation options
	 * @returns A promise that returns possible return data
	 */
	public navigateTo<Return = any>(
		_type: NavigationType,
		_destination: string | any,
		_data?: { [key in NavigationDataKey]?: any },
		_options: INavigationOptions = baseNavigationOptions
	): Promise<Return> {
		const options = { ...baseNavigationOptions, ..._options };
		const destination = this.addRolePrefix(_type, _destination);
		this.handleCurrentNavigationObject(options.closePreviousModals);
		this.addToHistory(_type, destination, _data, options);
		this.addToNavigationData(_data);
		switch (_type) {
			case NavigationType.modal:
				return this.openModal<Return>(destination);
			case NavigationType.popover:
				return this.openPopover<Return>(destination, (_data as any)?.clickEvent);
			case NavigationType.page:
				this.openPage(destination);
				return Promise.resolve(null) as Promise<Return>;
		}
	}

	/**
	 * A method used to navigate back to the previous component or page.
	 * When coming from a modal or popover, the modal or popover is closed
	 * @param _data The data that needs to be passed back from a component or popover
	 * @param closePreviousModals [default = true] Whether or not to close the previous modals
	 */
	public async navigateBack(
		_data?: { [key: string]: any } | boolean,
		closePreviousModals: boolean = true
	): Promise<void> {
		const current = this.history.pop();
		switch (current?.type) {
			case NavigationType.modal:
				await (current.modalInstance as HTMLIonModalElement).dismiss(_data).catch();
				break;
			case NavigationType.popover:
				await (current.popoverInstance as HTMLIonPopoverElement).dismiss(_data).catch();
				break;
		}
		const previous = this.history[this.history.length - 1];
		if (previous && previous.type !== NavigationType.page && current.type !== NavigationType.page) {
			if (
				this.lastPoppedHistory?.options.closePreviousModals !== false &&
				(this.lastPoppedHistory === previous || closePreviousModals)
			) {
				void this.navigateTo(previous.type, previous.destination, previous.data, {
					addToHistory: false,
				}).catch();
			}
		} else if (!previous) {
			if (!current || current.type === NavigationType.page) {
				this.navigationController.back();
			}
		} else {
			const previousPage = findLast(
				this.history,
				(navigationObject) => navigationObject.type === NavigationType.page
			) || { type: NavigationType.page, destination: '/', options: baseNavigationOptions };
			void this.navigateTo(previousPage.type, previousPage.destination, previousPage.data, {
				addToHistory: false,
			}).catch();
			if (previousPage !== previous) {
				void this.navigateTo(previous.type, previous.destination, previous.data, {
					addToHistory: false,
				}).catch();
			}
		}
		this.lastPoppedHistory = current;
	}

	public isActive(_route: string): boolean {
		const route = this.addRolePrefix(NavigationType.page, _route);
		return this.router.isActive(route, false);
	}

	/**
	 * A method that returns the navigation data based on a key
	 * @param _key The key {@link NavigationDataKey} that links to data
	 * @returns The navigation data
	 */
	public getNavigationData<I = any>(_key: NavigationDataKey): I {
		return this.navigationDataManager.getData(_key);
	}

	/**
	 * A method that resets the history chain of the navigation service
	 */
	public resetNavigation(): void {
		this.history = new Array();
	}

	/**
	 * A method that adds the navigation object to the navigation history
	 * @param {NavigationType} type The type of the navigation to use
	 * @param destination The destination to navigate to (either an url or a component)
	 * @param data Data to pass to the component
	 * @param _options The navigation options
	 */
	public addToHistory(
		type: NavigationType,
		destination: string | any,
		data?: { [key in NavigationDataKey]?: any },
		_options: INavigationOptions = baseNavigationOptions
	): void {
		const options = { ...baseNavigationOptions, ..._options };
		if (options.addToHistory) {
			this.history.push({ type, destination, data, options });
		}
	}

	/**
	 * When using mobile back navigation on full screen modals, standard they don't dismiss.
	 * But the underlying page is then returned, which gives weird behavior. This is solved by listening to
	 * the underlying return, then closing the modal, and navigating back to the original page
	 */
	public handleMobileModalBackBehavior(): void {
		this.router.events
			.pipe(
				filter((evt: any) => evt instanceof RoutesRecognized),
				pairwise()
			)
			.subscribe(async (events: RoutesRecognized[]) => {
				if (await this.modalController.getTop()) {
					await this.modalController.dismiss();
					this.resetNavigation();
					this.navigateTo(NavigationType.page, events[0].urlAfterRedirects);
				}
				if (await this.popoverController.getTop()) {
					await this.popoverController.dismiss();
					this.resetNavigation();
					this.navigateTo(NavigationType.page, events[0].urlAfterRedirects);
				}
			});
	}

	/**
	 * A method that handles closing modals or popovers of the modal or popover when going to navigate to another component or page
	 * @param closePreviousModals Whether or not to close the previous modals
	 */
	private handleCurrentNavigationObject(closePreviousModals: boolean): void {
		if (closePreviousModals) {
			const current = this.getCurrentNavigationObject();
			switch (current?.type) {
				case NavigationType.modal:
					void (current.modalInstance as HTMLIonModalElement)?.dismiss().catch();
					break;
				case NavigationType.popover:
					void (current.popoverInstance as HTMLIonPopoverElement)?.dismiss().catch();
					break;
			}
		}
	}

	/**
	 * A method that navigates to a page based on an url
	 * @param _url The url to navigate to
	 */
	private openPage(_url: string): void {
		void this.router.navigate([_url]).catch();
	}

	/**
	 * Initialize the navigation service
	 */
	public initializeNavigation(): void {
		this.addToHistory(NavigationType.page, this.router.url, null);
	}

	/**
	 * A method that opens a modal based on a component
	 * @param _component The component to open as modal
	 * @returns A promise that returns data returned from the component
	 */
	private async openModal<Return>(_component: any): Promise<Return> {
		return new Promise(async (resolve) => {
			const modal = await this.modalController.create({
				component: _component,
				cssClass: 'auto-height',
				backdropDismiss: false,
			});
			void modal
				.onDidDismiss()
				.then((result) => resolve(result?.data))
				.catch();
			this.addModalToCurrentNavigateObject(modal);
			await modal.present();
		});
	}

	/**
	 * A method that opens a popover based on a component
	 * @param _component The component to open as popover
	 * @param _clickEvent The click event to attach the popover to
	 * @returns A promise that returns data returned from the component
	 */
	private async openPopover<Return>(_component: any, _clickEvent?: PointerEvent): Promise<Return> {
		return new Promise(async (resolve) => {
			const popover = await this.popoverController.create({
				component: _component,
			});
			if (_clickEvent) {
				popover.event = _clickEvent;
			}
			void popover
				.onDidDismiss()
				.then((result) => resolve(result?.data))
				.catch();
			this.addPopoverToCurrentNavigateObject(popover);
			await popover.present();
		});
	}

	/**
	 * A method to add data to the navigation data, using the {@link NavigationDataManager}
	 * @param _data The data to add to the navigation data
	 */
	private addToNavigationData(_data?: { [key in NavigationDataKey]?: any }): void {
		if (_data) {
			Object.keys(_data).forEach((key) => {
				this.navigationDataManager.addData(key as NavigationDataKey, _data[key]);
			});
		}
	}

	/**
	 * A method to get the current (active) navigation object
	 * @returns The current (active) navigation object
	 */
	private getCurrentNavigationObject(): INavigationObject {
		return this.history[this.history.length - 1];
	}

	/**
	 * A method that adds the popover instance to the navigation object that created the popover
	 * @param _popover The popover instance
	 */
	private addPopoverToCurrentNavigateObject(_popover: HTMLIonPopoverElement): void {
		(this.history[this.history.length - 1].popoverInstance as HTMLIonPopoverElement) = _popover;
	}

	/**
	 * A method that adds the modal instance to the navigation object that created the modal
	 * @param _modal The modal instance
	 */
	private addModalToCurrentNavigateObject(_modal: HTMLIonModalElement): void {
		(this.history[this.history.length - 1].modalInstance as HTMLIonModalElement) = _modal;
	}

	/**
	 * Add the correct prefix (internal, client, partner) to the url
	 * @param _type The navigation type
	 * @param _destination The url before adding the prefix
	 * @returns the formatted destination
	 */
	private addRolePrefix(_type: NavigationType, _destination: string): string {
		if (_type === NavigationType.page) {
			let prefix: string;
			switch (this.userManager.getRole()) {
				case Role.PLANNER:
				case Role.TRAINER:
				case Role.ADMIN:
					prefix = 'internal';
					break;
				case Role.CLIENT:
					prefix = 'clients';
					break;
			}
			if (prefix && !_destination?.includes(prefix) && !_destination?.includes('opleidingen')) {
				_destination = `/${prefix}${_destination}`;
			}
		}
		return _destination;
	}
}
