import { ErrorType, StorageKey, UserOnCompanyStatus } from '@shared-libs/enums';
import { UserManager } from './user.manager';
import { Injectable } from '@angular/core';
import { ICompany } from '@shared-models/company.model';
import { CompanyUserProvider } from '@shared-providers/api/company-user.provider';
import { StorageService } from '@shared-services/storage.service';
import { BehaviorSubject } from 'rxjs';
import { remove } from 'lodash';
import { ErrorService } from '@shared-services/error.service';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from '@shared-services/validation.service';
import { AddContactInfoComponent } from '@client-components/add-contact-info-component/add-contact-info.component';
import { ClientsCompanyProvider } from '@client-providers/company.provider';
import { ModalController } from '@ionic/angular';

/* TODO: replace managers with redux strategy? */

/**
 * The manager for the company user objects.
 * A Manager is responsible for the state and reusability
 * (across the application) of certain properties
 */
@Injectable({
	providedIn: 'root',
})
export class CompanyManager {
	private companies: Array<Pick<ICompany, 'id' | 'name'>>;
	private subject: BehaviorSubject<Array<Pick<ICompany, 'id' | 'name'>>>;
	private actingCompanyId: string;
	private actingCompanySubject: BehaviorSubject<void>;

	constructor(
		protected readonly userManager: UserManager,
		protected readonly storageService: StorageService,
		private readonly companyUserProvider: CompanyUserProvider,
		private readonly errorService: ErrorService,
		private readonly translateService: TranslateService,
		private readonly validationService: ValidationService,
		private readonly companyProvider: ClientsCompanyProvider,
		private readonly modalController: ModalController
	) {
		if (this.userManager.isClient()) {
			this.initSubject();
		}
	}

	public async init(): Promise<void> {
		if (this.userManager.isClient()) {
			this.initSubject();
			await this.getCompaniesFromDatabase();
		}
	}

	/**
	 * Set the companies
	 * @param companies The companies
	 */
	public setCompanies(companies: Array<Pick<ICompany, 'id' | 'name'>>): void {
		this.subject.next(companies);
		this.companies = companies;
	}

	/**
	 * Remove a company from the list of managed companies
	 * when the to be removed company is the acting company, we pick another random one from the list
	 * @param company The company
	 */
	public async removeCompany(company: Pick<ICompany, 'id' | 'name'>): Promise<void> {
		if (await this.validationService.actionIsConfirmed('REMOVE_LINK_AS_CLIENT')) {
			if (this.companies.length <= 1) {
				this.errorService.showApplicationError(
					this.translateService.instant('SELECT_ACTING_COMPANY.REMOVE_COMPANY_ERROR'),
					ErrorType.Component
				);
				return;
			}

			this.companyUserProvider
				.removeUserLinkToCompany({ UserId: this.userManager.getUserId(), CompanyId: company.id })
				.subscribe(() => {
					if (this.actingCompanyId === company.id) {
						const otherCompany = this.companies.find((_company) => _company.id !== company.id);
						this.setActingCompanyId(otherCompany.id);
					}

					remove(this.companies, company);
					this.subject.next(this.companies);
				});
		}
	}

	/**
	 * Set the action company id
	 * @param actingCompanyId The new action company id
	 */
	public setActingCompanyId(actingCompanyId: string): void {
		this.actingCompanyId = actingCompanyId;
		this.actingCompanySubject.next();
		if (this.actingCompanyId) {
			this.storageService.setItem(StorageKey.actingCompanyId, this.actingCompanyId).subscribe(() => {});
		}
		void this.linkedCompanyHasAllContactInfo();
	}

	/**
	 * Get the action company id
	 * @returns the action company id
	 */
	public getActingCompanyId(): string {
		return this.actingCompanyId;
	}

	/**
	 * Return the status of the user on the acting company of the user
	 * @returns The UserOnCompanyStatus
	 */
	public getActingCompanyUserStatus(): UserOnCompanyStatus {
		return (this.companies.find((company) => company.id === this.actingCompanyId) as any)?.UserOnCompany.status;
	}

	/**
	 * Get the subject that fires when the acting company changes
	 * @returns The subject that return nothing
	 */
	public onActingCompanyChange(): BehaviorSubject<void> {
		return this.actingCompanySubject;
	}

	/**
	 * Get the subject that fires when add company is added to the list
	 * @returns The subject that returns the list of companies
	 */
	public onCompanyAdded(): BehaviorSubject<Array<Pick<ICompany, 'id' | 'name'>>> {
		return this.subject;
	}

	/**
	 * Return the subject that returns the companies
	 * @returns the subject that returns the companies
	 */
	public getCompaniesSubject(): BehaviorSubject<Array<Pick<ICompany, 'id' | 'name'>>> {
		return this.subject;
	}

	/**
	 * Get the companies for a user from the database
	 */
	public async getCompaniesFromDatabase(): Promise<void> {
		const companies = (await this.companyUserProvider.getCompaniesOfUser(this.userManager.getUserId()).toPromise())
			.data;
		this.setCompanies(companies);
		await this.initActingCompanyId();
	}

	public async linkedCompanyHasAllContactInfo(): Promise<void> {
		return new Promise((resolve) => {
			this.companyProvider.getMissingContactInfo().subscribe(async (result) => {
				if (!result.data || result.data.length === 0) resolve();
				else {
					const modal = await this.modalController.create({
						component: AddContactInfoComponent,
						backdropDismiss: false,
						cssClass: 'auto-height',
						componentProps: { missingProperties: result.data },
					});
					void modal
						.onDidDismiss()
						.then(() => {
							resolve();
						})
						.catch();
					await modal.present();
				}
			});
		});
	}

	/**
	 * Remove the companies from session
	 */
	public flush(): void {
		this.companies = null;
		this.actingCompanyId = null;
		this.subject?.unsubscribe();
		this.subject = null;
		this.actingCompanySubject?.unsubscribe();
		this.actingCompanySubject = null;
		this.storageService.removeItem(StorageKey.actingCompanyId).subscribe();
	}

	private initSubject(): void {
		if (!this.subject) {
			this.subject = new BehaviorSubject(null);
		}
		if (!this.actingCompanySubject) {
			this.actingCompanySubject = new BehaviorSubject(null);
		}
		void this.getCompaniesFromDatabase();
	}

	private async initActingCompanyId(): Promise<void> {
		if (!this.actingCompanyId) {
			try {
				const actingCompanyId = await this.storageService
					.getItem<string>(StorageKey.actingCompanyId)
					.toPromise();
				this.actingCompanyId = actingCompanyId ?? this.companies?.[0]?.id;
			} catch (err) {
				this.actingCompanyId = this.companies?.[0]?.id;
			}
		}
	}
}
