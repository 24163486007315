import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { cloneDeep, isArray, isDate, isEmpty, isInteger, isNumber, isString, some } from 'lodash';
import { VALIDATION } from './constants';
import { ValidationErrorLevel } from './enums';
import { IValidationError } from './interfaces';
import * as ibantools from 'ibantools';
import { endOfDay, isAfter, isBefore, isFuture, isSameDay, startOfDay, subYears } from 'date-fns';
import { DateHelper } from './helpers';
import { alpha3ToAlpha2 } from 'i18n-iso-countries';

/**
 * @static
 */
export class SharedFormValidators {
	/**
	 * This function validates a formcontrol that includes a date as value.
	 * It checks if the date is in the future.
	 * @param _control A bound formcontrol including a date as value
	 * @returns {IValidationError} returns null when valid and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static isTodayOrLater(_control: UntypedFormControl): IValidationError {
		if (!_control.value || !_control.touched) return null;
		const currentDate = DateHelper.parseDate(_control.value);
		const validationDate = startOfDay(new Date());
		if (isAfter(currentDate, validationDate) || isSameDay(currentDate, validationDate)) return null;

		return {
			inFuture: {
				messageKey: 'WORKBLOCK.TODAY_OR_LATER',
				level: ValidationErrorLevel.error,
			},
		};
	}

	/**
	 * This function validates a formcontrol that includes a date as value.
	 * It checks if the date is in the future.
	 * @param _control A bound formcontrol including a date as value
	 * @returns {IValidationError} returns null when valid and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static inFuture(_control: UntypedFormControl): IValidationError {
		if (!_control.value || !_control.touched) return null;
		if (isFuture(DateHelper.parseDate(_control.value))) return null;

		return {
			inFuture: {
				messageKey: 'WORKBLOCK.FUTURE',
				level: ValidationErrorLevel.error,
			},
		};
	}

	/**
	 * This function validates a formcontrol that includes a date as value.
	 * It checks if the date is min 16 years old.
	 * @param _control A bound formcontrol including a date as value
	 * @returns {IValidationError} returns null when valid and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static min16YearsOld(_control: UntypedFormControl): IValidationError {
		if (!_control.value) return null;
		if (isBefore(new Date(_control.value), subYears(endOfDay(new Date()), 16))) return null;

		return {
			toYoung: {
				messageKey: 'TRAINEE.YOUNGER_THAN_16',
				level: ValidationErrorLevel.error,
			},
		};
	}

	/**
	 * This function validates a formcontrol that includes a password as value.
	 * Check if a password meets the minimum requirements
	 * @example Minimum 7 characters and at least one number
	 * @param _control A bound formcontrol including an password as value
	 * @returns {IValidationError} returns null when valid or empty and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static password(_control: UntypedFormControl): IValidationError {
		if (isEmpty(_control.value)) return null;
		if (_control.value.length > 6 && /\d/.test(_control.value)) {
			return null;
		} else
			return {
				password: {
					messageKey: 'PASSWORD',
					level: ValidationErrorLevel.error,
				},
			};
	}

	/**
	 * This function validates a formcontrol that includes an email as value.
	 * It uses the {@link SharedFormValidators.EMAIL_REGEX} to test if the value is valid.
	 * @param _control A bound formcontrol including an email as value
	 * @returns {IValidationError} returns null when valid or empty and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static email(_control: UntypedFormControl): IValidationError {
		if (isEmpty(_control.value)) return null;
		if (VALIDATION.EMAIL_REGEX.test(_control.value)) {
			return null;
		} else
			return {
				email: {
					messageKey: 'EMAIL',
					level: ValidationErrorLevel.error,
				},
			};
	}

	/**
	 * This function validates a formcontrol that includes a phone or mobile number as value.
	 * It uses the {@link SharedFormValidators.PHONE_REGEX} to test if the value is valid.
	 * @param _control A bound formcontrol including a phone or mobile number as value
	 * @returns {IValidationError} returns null when valid or empty and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static phone(_control: UntypedFormControl): IValidationError {
		if (isEmpty(_control.value)) return null;
		if (VALIDATION.PHONE_REGEX.test(_control.value)) {
			return null;
		} else
			return {
				phone: {
					messageKey: 'PHONE',
					level: ValidationErrorLevel.error,
				},
			};
	}

	/**
	 * This function validates a formGroup that includes a phone and mobile number as value.
	 * It uses the {@link SharedFormValidators.PHONE_REGEX} to test if the value is valid.
	 * @param _control A bound formGroup including a phone and mobile number as value
	 * @returns {IValidationError} returns null when valid or empty and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static phoneOrMobile(_control: UntypedFormControl): IValidationError {
		const phone: string = _control.value.phone;
		const mobile: string = _control.value.mobile;
		if (!isEmpty(phone) || !isEmpty(mobile)) return null;
		else
			return {
				phoneOrMobile: {
					messageKey: 'PHONE_OR_MOBILE',
					level: ValidationErrorLevel.error,
				},
			};
	}

	/**
	 * This function validates a formcontrol that includes an address as value.
	 * when the input value is a string and it doesn't match the original address param
	 * then the address is changed without selecting an address from the autocomplete list. This makes it invalid.
	 * When the input value is not a string, it's an {@link SharedFormValidators.IAddress} object, and it is only valid when all properties are set.
	 * @param _originalAddress The original address as string
	 * @returns {IValidationError} returns null when valid or empty and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static address(_originalAddress: string): (AbstractControl: AbstractControl) => IValidationError | null {
		return (_control: UntypedFormControl): IValidationError | null => {
			if (isEmpty(_control.value)) return null;
			if (typeof _control.value === 'string') {
				if (_control.value === _originalAddress) {
					return null;
				} else
					return {
						address: {
							messageKey: 'ADDRESS_CHANGED',
							level: ValidationErrorLevel.error,
						},
					};
			} else {
				const addressClone = cloneDeep(_control.value);
				delete addressClone.floor;
				if (!some(addressClone.value, (value) => !value || value == null)) {
					return null;
				} else
					return {
						address: {
							messageKey: 'ADDRESS',
							level: ValidationErrorLevel.error,
						},
					};
			}
		};
	}

	/**
	 * This function validates a formcontrol that includes an array as value.
	 * The string will be checked to have a valid min length
	 * When a number is passed as parameter, this is the minimum value to be valid
	 * @param min The min value
	 * @returns {IValidationError} returns null when valid or empty and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 * @see {@link SharedFormValidators.ValidationErrorComponent} ValidationErrorComponent
	 */
	public static minLength(min: number): (AbstractControl: AbstractControl) => IValidationError | null {
		return (_control: UntypedFormControl): IValidationError | null => {
			if (!isArray(_control.value)) return;
			if (_control.value.length === 0) return;

			if (_control.value?.length >= min) {
				return null;
			}

			return {
				minLength: {
					messageKey: 'ARRAY.MIN_LENGTH',
					level: ValidationErrorLevel.error,
				},
			};
		};
	}

	/**
	 * This function validates a formcontrol that includes an array as value.
	 * The string will be checked to have a valid max length
	 * When a number is passed as parameter, this is the maximum value to be valid
	 * @param max The max value
	 * @returns {IValidationError} returns null when valid or empty and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 * @see {@link SharedFormValidators.ValidationErrorComponent} ValidationErrorComponent
	 */
	public static maxLength(max: number): (AbstractControl: AbstractControl) => IValidationError | null {
		return (_control: UntypedFormControl): IValidationError | null => {
			if (!isArray(_control.value)) return;
			if (_control.value.length === 0) return;

			if (_control.value?.length <= max) {
				return null;
			}

			return {
				maxLength: {
					messageKey: 'ARRAY.MAX_LENGTH',
					level: ValidationErrorLevel.error,
				},
			};
		};
	}

	/**
	 * This function validates a formcontrol that includes a string as value.
	 * The string will be checked to be a valid number when parsed
	 * When a number is passed as parameter, this is the minimum value to be valid
	 * @param _min The min value
	 * @param _max The max value
	 * @returns {IValidationError} returns null when valid or empty and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static number(_min?: number, _max?: number): (AbstractControl: AbstractControl) => IValidationError | null {
		return (_control: UntypedFormControl): IValidationError | null => {
			if (!isNaN(_control.value as any)) {
				if (!_min || _min <= parseFloat(_control.value)) {
					if (!_max || _max >= parseFloat(_control.value)) return null;
					else {
						return {
							numbers: {
								messageKey: 'NUMBER.MAX',
								level: ValidationErrorLevel.error,
							},
						};
					}
				} else {
					return {
						numbers: {
							messageKey: 'NUMBER.MIN',
							level: ValidationErrorLevel.error,
						},
					};
				}
			} else {
				return {
					numbers: {
						messageKey: 'NUMBER.NOT_VALID',
						level: ValidationErrorLevel.error,
					},
				};
			}
		};
	}

	/**
	 * This function validates a formControl that includes a number as value.
	 * It uses to check the number is an integer.
	 * @param _control A bound formControl including a number as value
	 * @returns {IValidationError} returns null when valid or empty and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static integer(_control: UntypedFormControl): IValidationError {
		if (isInteger(_control.value) || _control.value === null) return null;
		else
			return {
				integer: {
					messageKey: 'INTEGER',
					level: ValidationErrorLevel.error,
				},
			};
	}

	/**
	 * This function validates a formcontrol that includes an IBAN number as value.
	 * It uses an external package to test if the value is valid.
	 * @param _control A bound formcontrol including an IBAN number as value
	 * @returns {IValidationError} returns null when valid or empty and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static iban(_control: UntypedFormControl): IValidationError {
		if (isEmpty(_control.value)) return null;
		if (ibantools.isValidIBAN(ibantools.electronicFormatIBAN(_control.value))) {
			return null;
		} else
			return {
				iban: {
					messageKey: 'IBAN',
					level: ValidationErrorLevel.error,
				},
			};
	}

	/**
	 * This function validates a formcontrol that includes a VAT number as value.
	 * It uses the {@link SharedFormValidators.VAT_REGEX} to test if the value is valid.
	 * @param _control A bound formcontrol including a VAT number as value
	 * @returns {IValidationError} returns null when valid or empty and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static btwNumberHasCountryCode(_control: UntypedFormControl): IValidationError {
		if (isEmpty(_control.value)) return null;
		const value = (_control.value as string).replace(new RegExp('[-.\\s]+', 'g'), '');
		if (VALIDATION.COUNTRY_CODE.test(value.slice(0, 2))) {
			return null;
		} else
			return {
				taxCountry: {
					messageKey: 'TAX_COUNTRY',
					level: ValidationErrorLevel.error,
				},
			};
	}

	/**
	 * This function validates a formcontrol that includes a VAT number as value.
	 * It uses the {@link SharedFormValidators.VAT_REGEX} to test if the value is valid.
	 * @param _control A bound formcontrol including a VAT number as value
	 * @returns {IValidationError} returns null when valid or empty and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static btwNumber(_control: UntypedFormControl): IValidationError {
		if (isEmpty(_control.value)) return null;
		const value = (_control.value as string).replace(new RegExp('[-.\\s]+', 'g'), '');
		const country = value.slice(0, 2).toUpperCase();
		if (VALIDATION.VAT[country]?.test(value)) {
			return null;
		} else
			return {
				tax: {
					messageKey: 'TAX',
					level: ValidationErrorLevel.error,
				},
			};
	}

	/**
	 * This function validates a formcontrol that includes a Organization number as value.
	 * we need a country code to validate the organization number, so we get it from the vat control, otherwise the invoiceAddress control and last the address control
	 * @param vat The vat formControl
	 * @param address The address formControl
	 * @param invoiceAddress The invoice address formControl
	 * @returns {IValidationError} returns null when valid or empty and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static organizationNumber(
		vat: AbstractControl,
		address: AbstractControl,
		invoiceAddress: AbstractControl
	): (AbstractControl: AbstractControl) => IValidationError | null {
		return (_control: UntypedFormControl): IValidationError | null => {
			if (isEmpty(_control.value)) return null;
			let countryCode: string;

			if (vat.valid && vat.value) {
				countryCode = vat.value.slice(0, 2).toUpperCase();
			} else if (invoiceAddress.valid) {
				const alpha3code = invoiceAddress.value.country;
				countryCode = alpha3ToAlpha2(alpha3code);
			} else if (address.valid) {
				const alpha3code = address.value.country;
				countryCode = alpha3ToAlpha2(alpha3code);
			}

			if (!countryCode || !VALIDATION.COUNTRY_CODE.test(countryCode)) {
				return {
					tax: {
						messageKey: 'ORGANIZATION_NUMBER_NO_COUNTRY_CODE',
						level: ValidationErrorLevel.error,
					},
				};
			}

			const value = (_control.value as string).replace(new RegExp('[-.\\s]+', 'g'), '');
			if (VALIDATION.VAT[countryCode]?.test(`${countryCode}${value}`)) {
				return null;
			} else
				return {
					tax: {
						messageKey: 'ORGANIZATION_NUMBER',
						level: ValidationErrorLevel.error,
					},
				};
		};
	}

	/**
	 * This function validates a formcontrol that includes a string as value.
	 * It uses the {@link SharedFormValidators.NAME_REGEX} to test if the value is valid.
	 * @param _control A bound formcontrol including a string as value
	 * @returns {IValidationError} returns null when valid or empty and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static strictString(_control: UntypedFormControl): IValidationError {
		if (isEmpty(_control.value)) return null;
		if (VALIDATION.NAME_REGEX.test(_control.value)) {
			return null;
		} else
			return {
				string: {
					messageKey: 'NAME',
					level: ValidationErrorLevel.error,
				},
			};
	}

	/**
	 * This function validates a formcontrol that includes a value.
	 * It checks if the value is empty.
	 * @param _control A bound formcontrol including a value
	 * @returns {IValidationError} returns null when valid and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static required(_control: UntypedFormControl): IValidationError {
		if (
			_control.value === true ||
			_control.value === false ||
			isNumber(_control.value) ||
			isDate(_control.value) ||
			(isArray(_control.value) && _control.value.length > 0) ||
			(isString(_control.value) && !isEmpty((_control.value as string).replace(/ /g, '')))
		) {
			return null;
		} else
			return {
				required: {
					messageKey: 'REQUIRED',
					level: ValidationErrorLevel.error,
				},
			};
	}

	/**
	 * This function validates a formcontrol that includes a zipcode as value.
	 * It uses the {@link SharedFormValidators.ZIPCODE_REGEX} to test if the value is valid
	 * @param _control A bound formcontrol including a zipcode as value
	 * @returns {IValidationError} returns null when valid and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static zipcode(_control: UntypedFormControl): IValidationError {
		if (isEmpty(_control.value)) return null;
		if (VALIDATION.ZIPCODE_REGEX.test(_control.value)) {
			return null;
		}
		return {
			zipcode: {
				messageKey: 'ZIPCODE',
				level: ValidationErrorLevel.error,
			},
		};
	}

	/**
	 * This function validates a formcontrol that includes a Date as value.
	 * It checks the value as Date instance to test if the value is valid
	 * @param _control A bound formcontrol including a Date as value
	 * @returns {IValidationError} returns null when valid and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static date(_control: UntypedFormControl): IValidationError {
		const returnNotValid = () => ({
			date: {
				messageKey: 'DATE',
				level: ValidationErrorLevel.error,
			},
		});
		try {
			if (_control.value instanceof Date) {
				return null;
			}
			return returnNotValid();
		} catch (err) {
			return returnNotValid();
		}
	}

	/**
	 * This function validates a formgroup that includes a password and confirmation as value.
	 * It checks if the password and the confirmation are equal.
	 * @param _control A bound formgroup including a password and confirmation as value
	 * @returns {IValidationError} returns null when valid and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static passwordConfirmation(_control: UntypedFormGroup): IValidationError {
		const password: string = _control.value.new;
		const confirmation: string = _control.value.confirmation;

		if (password === confirmation) return null;

		return {
			passwordConfirmation: {
				messageKey: 'PASSWORD_CONFIRMATION',
				level: ValidationErrorLevel.error,
			},
		};
	}

	/**
	 * This function validates a formcontrol that includes a boolean as value.
	 * It checks the value as boolean instance to test if the checkbox is checked
	 * @param _control A bound formcontrol including a boolean as value
	 * @returns {IValidationError} returns null when valid and a ValidationError that can be used by the ValidationErrorComponent when not valid
	 */
	public static checkboxIsChecked(_control: UntypedFormControl): IValidationError {
		if (_control.value === true) return null;

		return {
			checkbox: {
				messageKey: 'CHECKBOX',
				level: ValidationErrorLevel.error,
			},
		};
	}
}
