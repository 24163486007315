import { Injectable } from '@angular/core';
import { ILoginCredentials } from '@shared-libs/interfaces';
import { NavController } from '@ionic/angular';
import { UserManager } from '@shared-managers/user.manager';
import { StorageService } from '@shared-services/storage.service';
import { BackgroundColorService } from '@shared-services/background-color.service';
import { NavigationService } from '@shared-services/navigation.service';
import { NavigationType, Role } from '@shared-libs/enums';
import { UserDetailsManager } from '@shared-managers/user-details.manager';
import { IUser } from '@shared-models/user.model';
import { SharedUserProvider } from '@shared-providers/api/user.provider';
import jwt_decode from 'jwt-decode';
import { BadgeService } from '@internal-services/badge.service';
import { StateManager } from '@shared-managers/state.manager';
import { NavigationDataManager } from '@shared-managers/navigation-data.manager';
import { CompanyManager } from '@shared-managers/company.manager';

/**
 * The authentication service to handle authentication logic
 */
@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	private authenticated: boolean;

	constructor(
		private readonly loginProvider: SharedUserProvider,
		private readonly navController: NavController,
		private readonly userDetailsManager: UserDetailsManager,
		private readonly userManager: UserManager,
		private readonly storageService: StorageService,
		private readonly badgeService: BadgeService,
		private readonly backgroundColorService: BackgroundColorService,
		private readonly navigationService: NavigationService,
		private readonly stateManager: StateManager,
		private readonly navigationDataManager: NavigationDataManager,
		private readonly companyManager: CompanyManager
	) {}

	/**
	 * Login, i.e. get a token from the api to authenticate future requests
	 * When a user is logged in, the user object is added the the user manager
	 * and saved if the user wants to keep being logged in.
	 * @param _credentials The login credentials as {@link ILoginCredentials}
	 */
	public login(_credentials: ILoginCredentials): void {
		this.loginProvider.login(_credentials).subscribe((result) => {
			const user: IUser = jwt_decode(result.data.access_token);
			this.userManager.setUser(user);
			this.userManager.setToken(result.data.access_token);
			this.userManager.setRefreshToken(result.data.refresh_token);

			if (_credentials.keepMeLoggedIn) {
				this.keepCurrentUserLoggedIn();
			}

			this.authenticated = true;
			this.navigateToRoleDefault(user.role);
		});
	}

	public keepCurrentUserLoggedIn(): void {
		this.userManager.saveUser();
		this.userManager.saveToken();
		this.userManager.saveRefreshToken();
	}

	/**
	 * Navigate to routing module for a specific role
	 * @param role The user's role
	 */
	public navigateToRoleDefault(role: Role): void {
		switch (role) {
			case Role.PLANNER:
			case Role.TRAINER:
			case Role.ADMIN:
				void this.navigationService.navigateTo(NavigationType.page, '/internal').catch();
				break;
			case Role.CLIENT:
				void this.navigationService.navigateTo(NavigationType.page, '/clients').catch();
				break;
		}
	}

	/**
	 * Logout the current user
	 * All user and application data is removed from session and persistent storage
	 */
	public logout(): void {
		this.userManager.removeUser();
		this.userDetailsManager.removeUserDetails();
		this.storageService.clearAll();
		this.badgeService.destroy();
		this.navigationDataManager.flush();
		this.stateManager.flush();
		this.companyManager.flush();
		this.backgroundColorService.removeBackgroundColor();
		this.authenticated = false;
		void this.navController.navigateBack('login', { animated: false }).catch();
	}

	/**
	 * A function used by the router authguard to make sure a user is authenticated when trying to access an url
	 * @returns Whether the user is authenticated
	 */
	public isAuthenticated(): Promise<boolean> {
		return new Promise((resolve) => {
			this.userManager.isAuthenticated().subscribe(
				async () => {
					this.authenticated = true;
					resolve(this.authenticated);
				},
				() => {
					this.authenticated = false;
					resolve(this.authenticated);
				}
			);
		});
	}
}
