import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
	AbstractControl,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { InputMode } from '@shared-libs/enums';

@Component({
	selector: 'form-input',
	templateUrl: 'form-input.component.html',
	styleUrls: ['./form-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FormInputComponent),
			multi: true,
		},
	],
})
export class FormInputComponent implements ControlValueAccessor, OnInit {
	@Input() public label: string;
	@Input() public type: string = 'text';
	@Input() public inputmode: InputMode = InputMode.TEXT;
	@Input() public readonly: boolean = false;
	@Input() public autocomplete: string = 'off';
	@Input() public formControl: UntypedFormControl | UntypedFormGroup;
	public controlName: string;
	public value: string;

	public disabled: boolean;
	public onChange: (_: any) => void;
	public onTouched: () => void;

	constructor() {
		this.onChange = (_: any) => {};
		this.onTouched = () => {};
		this.disabled = false;
	}

	public inputChanged(value: string): void {
		this.value = value;
		this.onChange(this.value);
		this.onTouched();
	}

	public ngOnInit(): void {
		this.controlName = this.getControlName(this.formControl);
	}

	public getControlName(c: AbstractControl): string | null {
		const formGroup = c.parent.controls;
		return Object.keys(formGroup).find((name) => c === formGroup[name]) || null;
	}

	public writeValue(value: string): void {
		this.value = value;
	}
	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	public setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
