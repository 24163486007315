import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/shared/guards/auth.guard';

const routes: Routes = [
	{
		path: 'internal',
		loadChildren: () => import('./modules/internal/internal.module').then((m) => m.InternalModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'clients',
		loadChildren: () => import('./modules/clients/clients.module').then((m) => m.ClientsModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'intro',
		loadChildren: () =>
			import('./modules/public/intro/gui/pages/intro-page/intro.module').then((m) => m.IntroPageModule),
	},
	{
		path: 'login',
		loadChildren: () =>
			import('./modules/public/login/gui/pages/login-page/login.module').then((m) => m.LoginPageModule),
	},
	{
		path: 'register',
		loadChildren: () =>
			import('./modules/public/register/gui/pages/register-page/register.page.module').then(
				(m) => m.RegisterPageModule
			),
	},
	{
		path: 'reset',
		loadChildren: () =>
			import('./modules/public/login/gui/pages/reset-page/reset.module').then((m) => m.ResetPageModule),
	},
	{
		path: 'opleidingen',
		loadChildren: () =>
			import('./modules/public/trainings/gui/pages/trainings-page/trainings.module').then(
				(m) => m.PublicTrainingsPageModule
			),
	},
	{
		path: 'registreer-opleiding/:internalId',
		loadChildren: () =>
			import(
				'./modules/public/register-on-training/gui/pages/register-on-training-page/register-on-training.module'
			).then((m) => m.RegisterOnTrainingPageModule),
	},
	{ path: 'opleiding', redirectTo: '/clients/book-training', pathMatch: 'full' },
	{ path: '', redirectTo: 'intro', pathMatch: 'full' },
	{ path: '**', redirectTo: 'intro' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: false })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
