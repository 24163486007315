import { Api } from '@shared-providers/api.provider';
import { Observable } from 'rxjs';
import { IApiObject, IDataQuery, IListData } from '@shared-libs/interfaces';
import { Injectable } from '@angular/core';
import { Provider } from '@shared-providers/Provider';
import { LoadingService } from '@shared-services/loading.service';
import { ITraining } from '@internal-models/training.model';
import { IBookClosedTrainingForm, IBookOpenTrainingForm } from '@shared-factories/forms/book-training-form.factory';
import { formatQueryUrl } from '@shared-libs/helpers';
import { ITrainee } from '@shared-models/trainee.model';

@Injectable({
	providedIn: 'root',
})
export class SharedTrainingProvider extends Provider<ITraining> {
	protected readonly url: string = '/trainings';

	constructor(
		protected readonly api: Api,
		protected readonly loadingService: LoadingService
	) {
		super(loadingService);
	}

	public getAllTraineesOnTrainingsOfCompany(
		query: IDataQuery,
		companyId: string
	): Observable<IApiObject<IListData<ITraining>>> {
		return this.process(
			this.api.get(`${this.url}/company/${companyId}/trainees?query=${formatQueryUrl(query)}`),
			!query.offset
		) as Observable<IApiObject>;
	}

	public getAll(query: IDataQuery): Observable<IApiObject<IListData<ITraining>>> {
		return this.process(
			this.api.get(`${this.url}?query=${formatQueryUrl(query)}`),
			!query.offset
		) as Observable<IApiObject>;
	}

	public getAllReserved(query: IDataQuery): Observable<IApiObject<IListData<ITraining>>> {
		return this.process(
			this.api.get(`${this.url}/reserved?query=${formatQueryUrl(query)}`),
			!query.offset
		) as Observable<IApiObject>;
	}

	public getAllCancelled(query: IDataQuery): Observable<IApiObject<IListData<ITraining>>> {
		return this.process(
			this.api.get(`${this.url}/cancelled?query=${formatQueryUrl(query)}`),
			!query.offset
		) as Observable<IApiObject>;
	}

	public getAllNamesForBooking(): Observable<IApiObject<Array<Pick<ITraining, 'nameNL' | 'nameEN'>>>> {
		return this.process(this.api.get(`${this.url}/names`)) as Observable<IApiObject>;
	}

	public getAllOfCompany(query: IDataQuery, companyId: string): Observable<IApiObject<IListData<ITraining>>> {
		return this.process(
			this.api.get(`${this.url}/company/${companyId}?query=${formatQueryUrl(query)}`),
			!query.offset
		) as Observable<IApiObject>;
	}

	public getAllForBooking(query: IDataQuery): Observable<IApiObject<IListData<ITraining>>> {
		return this.process(
			this.api.get(`${this.url}/open?query=${formatQueryUrl(query)}`),
			!query.offset
		) as Observable<IApiObject>;
	}

	public getOne(trainingId: string): Observable<IApiObject<ITraining>> {
		return this.process(this.api.get(`${this.url}/${trainingId}`)) as Observable<IApiObject>;
	}

	public bookOpen(training: IBookOpenTrainingForm): Observable<IApiObject<ITraining>> {
		return this.process(this.api.post('/training-bookings/open', training)) as Observable<IApiObject>;
	}

	public bookClosed(training: IBookClosedTrainingForm): Observable<IApiObject<ITraining>> {
		return this.process(this.api.post(`${this.url}/closed`, training)) as Observable<IApiObject>;
	}

	public downloadAttendancesList(trainingId: string): Observable<IApiObject> {
		return this.process(
			this.api.download(`${this.url}/${trainingId}/attendances/download`, `Aanwezigheidslijst.pdf`, {})
		) as Observable<IApiObject>;
	}

	public updateTraineesOnTraining(trainingId: string, Trainees: Array<ITrainee>): Observable<IApiObject<ITraining>> {
		return this.process(this.api.put(`${this.url}/${trainingId}/trainees`, { Trainees })) as Observable<IApiObject>;
	}
}
