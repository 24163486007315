import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { validateACL } from '@shared-libs/acl';
import { UserManager } from '@app/modules/shared/managers/user.manager';
import { AuthenticationService } from '@shared-services/authentication.service';
import { Observable } from 'rxjs';

/**
 * A guard that checks if the user is authenticated
 * @see {@link AuthenticationService#isAuthenticated}
 */
@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(
		public readonly router: Router,
		public readonly authenticationService: AuthenticationService,
		public readonly userManager: UserManager
	) {}

	/**
	 * Overridden Angular function that contains guard logic and handles when it's not compliant
	 * It checks whether the user is authenticated or not and checks the role of the user against the ACL
	 * @param route The activated route
	 * @param state The router
	 * @returns Whether the user is authenticated or not in an Observable
	 */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return new Observable((subscriber) => {
			this.authenticationService
				.isAuthenticated()
				.then((isAuthenticated) => {
					if (isAuthenticated) {
						if (validateACL(state.url, this.userManager.getRole())) {
							subscriber.next(true);
						} else {
							void this.router.navigate(['/login']).catch();
							subscriber.next(false);
						}
					} else {
						void this.router.navigate(['/login']).catch();
						subscriber.next(false);
					}
					subscriber.complete();
				})
				.catch(() => {
					void this.router.navigate(['/login']).catch();
					subscriber.next(false);
					subscriber.complete();
				});
		});
	}
}
