import { Injectable, Injector } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import { ErrorComponent } from '@structural-components/error-component/error.component';
import { IApiError } from '@shared-libs/interfaces';
import { ValidationService } from './validation.service';
import { ErrorType } from '@shared-libs/enums';
import { TranslateService } from '@ngx-translate/core';
import { isArray } from 'lodash';

/**
 * The error service to handle errors and error messages across the application
 */
@Injectable({
	providedIn: 'root',
})
export class ErrorService {
	private apiErrorIsShowing: boolean = false;

	constructor(
		private readonly popoverController: PopoverController,
		private readonly toastController: ToastController,
		private readonly validationService: ValidationService,
		private readonly injector: Injector
	) {}

	/**
	 * Shows the ErrorComponent in a popover containing the error message returned from the API
	 * @param _error The returned error
	 * @returns An empty promise to confirm the actual presentation of the popover
	 */
	public async showApiError(_error: any): Promise<void> {
		if (!this.apiErrorIsShowing) {
			this.apiErrorIsShowing = true;
			const getErrorMessage = (_error: any) => {
				if (isArray(_error?.error?.message || _error.message)) {
					return (_error?.error?.message || (_error.message as Array<string>)).join(', ');
				} else return this.injector.get(TranslateService).instant(_error.error.message || _error.message);
			};
			const error: IApiError = {
				status: _error.status,
				message: getErrorMessage(_error),
			};
			const popover = await this.popoverController.create({
				component: ErrorComponent,
				componentProps: { error },
			});
			void popover
				.onDidDismiss()
				.then(() => {
					this.apiErrorIsShowing = false;
				})
				.catch();
			await popover.present();
		}
	}

	/**
	 * Show a user error, can be shown as toast (default) or in a component
	 * @param _message The error to show to the user
	 * @param type The error type
	 */
	public showApplicationError(_message: string, type: ErrorType = ErrorType.Toast): void {
		switch (type) {
			case ErrorType.Validation:
				void this.showValidationError(_message);
				break;
			case ErrorType.Component:
				void this.showComponentError(_message);
				break;
			case ErrorType.PermanentToast:
				this.showPermanentToastError(_message);
				break;
			case ErrorType.Toast:
			default:
				this.showToastError(_message);
				break;
		}
	}

	private showToastError(_message: string): void {
		void this.toastController
			.create({
				message: _message,
				duration: 4000,
				cssClass: '_error-toast',
				position: this.validationService.isOnMobileTemplate() ? 'middle' : 'bottom',
			})
			.then((toast) => {
				void toast.present().catch();
			})
			.catch();
	}

	private showPermanentToastError(_message: string): void {
		void this.toastController
			.create({
				message: _message,
				buttons: [
					{
						text: 'Ok',
						handler: () => {},
					},
				],
				cssClass: '_error-toast',
				position: this.validationService.isOnMobileTemplate() ? 'middle' : 'bottom',
			})
			.then((toast) => {
				void toast.present().catch();
			})
			.catch();
	}

	private async showValidationError(message: string): Promise<void> {
		const popover = await this.popoverController.create({
			component: ErrorComponent,
			componentProps: { error: { message }, isValidation: true },
		});
		await popover.present();
	}

	private async showComponentError(_message: string): Promise<void> {
		const popover = await this.popoverController.create({
			component: ErrorComponent,
			componentProps: { error: { message: _message } },
		});
		await popover.present();
	}
}
