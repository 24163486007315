import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Optional, SkipSelf } from '@angular/core';
import { ErrorComponentModule } from '@structural-components/error-component/error.component.module';
import { DirectivesModule } from '@shared-directives/directives.module';
import { ConfirmationComponentModule } from './gui/structural-components/confirmation-component/confirmation.component.module';

@NgModule({
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [ErrorComponentModule, ConfirmationComponentModule, DirectivesModule],
})
export class SharedModule {
	constructor(@Optional() @SkipSelf() parentModule?: SharedModule) {
		if (parentModule) {
			throw new Error('SharedModule is already loaded. Import it in the AppModule only');
		}
	}
}
